.stub {
  width: 75%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 36px;
  margin: 50px auto;
  /*width: calc(100% - 398px);*/
  /*max-width: 1240px;*/
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
