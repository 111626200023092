.menu {
    width: 100%;
    height: auto;
    background: #B9C5DD;
    opacity: 0.7;
    align-self: flex-end;
    justify-self: flex-end;
    position: relative;
}

.menu__second {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    z-index: 2;
    margin: 10px 0;
    visibility: hidden;
}

.menu__second_mobile {
    border: #692978 1px solid;
    visibility: hidden;
}


.menu__visible {
    visibility: visible;
}

.menu__title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    z-index: 2;
    margin: 0 30px 0 0;
}

.menu__button {
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    line-height: 130%;
    color: black;
    margin: 0 25px 0 0;
    display: block;
}

.menu__button:last-child {
    margin-right: 0;
}

.menu__button:hover {
    color: #692978;
}

.menu__button_active {
    color: #692978;
    border: 2px dotted #692978;
}

.menu__first {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    align-self: center;
    z-index: 2;
    margin: 0;
}

.menu__link {
    text-decoration: none;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin: 18px 0;
    width: 90%;
}

.menu__link:hover {
    color: #FFE0D2;
}

.menu__link_active {
    color: #FFE0D2;
}


@media all and (max-width: 740px) {
    .menu__link {
        font-size: 20px;
    }

    .menu__title {
        font-size: 16px;
    }

    .menu__button {
        font-size: 14px;
    }
}

@media all and (max-width: 640px) {
    /*    .menu {*/
    /*        height: 250px;*/
    /*    }*/
    .menu__link {
        font-size: 18px;
    }

    .menu__title {
        margin: 10px 5px;
    }

    .menu__second_mobile {
        position: absolute;
        top: -260px;
        right: 0;
        /*display: block;*/

    }

    .menu__second {
        flex-direction: column;
        justify-content: center;
        /*align-self: flex-end;*/
        align-items: center;
        position: absolute;
        top: 10px;
        right: 30px;
        background: #B9C5DD;
        width: 120px;
    }

    .menu__button {
        font-size: 14px;
        margin: 10px 5px;
    }
}

