.card {
    width: 30%;
    max-width: 333px;
    margin-right: 2%;
    margin-bottom: 50px;
    position: relative;
    background-color: #AE7FB9;
    border-radius: 75px 25px 75px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.card:nth-child(3n) {
    margin-right: 0;
}

.card__top {
    display: flex;
    margin: 5px 5px 5px 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10;
}

.card__img {
    display: block;
    width: calc(100% - 10px);
    max-width: 333px;
    height: auto;
    max-height: 222px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 75px 25px 10px 10px;
    margin: 5px;
}

.card__img_big {
    height: 333px;
    max-width: 500px;
}

.card__img:hover {
    opacity: 0.6;
}

.card__poem {
    display: block;
    width: 100%;
    height: 200px;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media all and (max-width: 940px) {
    .card {
        width: 45%;
    }

    .elements__item:nth-child(even) {
        margin-right: 0;
    }

    .elements__item:nth-child(odd) {
        margin-right: 2%;
    }
}

@media all and (max-width: 640px) {
    .card {
        width: 80%;
    }

    .elements__item:nth-child(odd) {
        margin-right: 0%;
    }
}

@media all and (max-width: 440px) {
    .card {
        width: 100%;
    }
}
