.footer {
  width: 100%;
  max-height: 300px;
  position: relative;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: space-between;*/
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../images/footer.jpg");
  padding: 50px 0;
}

.footer__block {
  display: flex;
  justify-content: space-between;
}

@media all and (max-width: 800px) {
  .footer__block {
    flex-direction: column;
    align-items: center;
  }
}
