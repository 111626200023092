.cardSer {
    width: 100%;
    margin: 30px auto;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}


