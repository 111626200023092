.popup {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0px;
  left: 0px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease-in;
  z-index: 10;
}

.popup__container {
  position: fixed;
  padding: 36px;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.popup__share {
  display: flex;
  width: 70%;
  /* background: no-repeat url("../images/share.png"); */
  /*justify-content: flex-end;*/
  /*margin: 15px 0 20px 0;*/
  /*padding-top: 15px;*/
  /*border-top: 2px solid #692978;*/
}

.popup__share_img {
  width: 70%;
}

.popup__share_item {
  font-size: 13px;
  line-height: 16px;
  margin: 0 20px 0 0;
}

.popup__share_item:last-child {
  margin: 0;
}

.popup__input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: "Inter", "Arial", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  padding: 13px 0 13px 2px;
  margin: 0 15px 15px 0;
  width: 100%;
  color: black;
}

.popup__input:focus {
  outline: 1px solid blue;
}

.popup__checkbox {
  display: flex;
}

.popup__checkbox_item {
  display: flex;
  margin-right: 10px;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__img {
  position: relative;
  z-index: 1;
  max-width: 75vw;
  max-height: 70vh;
  padding-bottom: 10px;
}

.popup__close {
  width: 40px;
  height: 40px;
  background-image: url(../images/close.svg);
  border: none;
  position: absolute;
  right: -35px;
  top: -35px;
  cursor: pointer;
  margin-bottom: 2px;
}

.popup__close:hover {
  opacity: 0.6;
}

.popup__block {
  display: flex;
}
